<template>
  <div>
    <!-- <section class="m-1">
      <b-row>
        <b-col cols="12" md="4"
          ><b-form-input
            v-model="searchValue"
            @input="onSearch"
            placeholder="search..."
          ></b-form-input
        ></b-col>
        <b-col cols="6" md="6"> </b-col>
        <b-col cols="6" md="2">
         
        </b-col>
      </b-row>
    </section> -->
    <b-card no-body header-tag="header" footer-tag="footer">
      <b-card-text>
        <b-table
          bordered
          responsive
          :fields="fields"
          :items="items"
          :busy="isBusy"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template #cell(sr)="data">
            {{
              data.index + 1 + (pagination.currentPage - 1) * pagination.limit
            }}
          </template>
          <template #cell(is_subscriber)="data">
            {{ data.item.is_subscriber == "Y" ? "Subscriber" : "Contact Us" }}
          </template>
          <template #cell(created_at)="data">
            {{ moment(data.item.created_at).format("DD/MM/YYYY hh:mm:ss ") }}
          </template>
        </b-table>
      </b-card-text>
      <template #footer>
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-end justify-content-end justify-content-sm-center"
            >
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.limit"
                @change="onChange"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import { GetContactUsListService } from "@/apiServices/MasterServices";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
export default {
  data() {
    return {
      moment: moment,
      searchValue: null,

      isBusy: false,
      fields: [
        {
          key: "sr",
          label: "SR",
        },
        {
          key: "is_subscriber",
          label: "Is Contact Us or subscriber",
          sortable: true,
        },
        {
          key: "subscriber_email",
          label: "Email",
          sortable: true,
        },

        {
          key: "subscriber_subject",
          label: "Subject",
          sortable: true,
        },
        {
          key: "subscriber_subject",
          label: "Subject",
          sortable: true,
        },
        {
          key: "subscriber_message",
          label: "Message",
          sortable: true,
        },

        {
          key: "created_at",
          label: "Date",
          sortable: true,
        },

        // {
        //   key: "action",
        //   label: "",
        // },
      ],
      items: [],
      pagination: {
        currentPage: 1,
        limit: 10,
        totalRows: 0,
      },
      debounce: null,
    };
  },
  beforeMount() {
    this.getContactUsList();
  },
  methods: {
    async onAddVoucher() {
      this.$router.push({
        name: "voucherform",
      });
    },
    async onSearch() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getContactUsList();
      }, 1000);
    },

    async getContactUsList() {
      try {
        this.isBusy = true;
        const response = await GetContactUsListService({
          limit: this.pagination.limit,
          currentPage: this.pagination.currentPage,
          search: this.searchValue,
        });
        if (response.data.status) {
          this.items = response.data.Records.data;
          if (!isNaN(response.data.Records.pagination.total)) {
            this.pagination.totalRows = response.data.Records.pagination.total;
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },

    onChange() {
      this.$nextTick(() => {
        this.getContactUsList();
      });
    },
  },
};
</script>
